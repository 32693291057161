* {
  cursor:none;

}

.App {
  text-align: center;
  width: 100%;
  scroll-behavior: smooth;
}

h1 {
  color: white;
  background-color: #010208;
}
body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  /* display: inline-flex; */
  align-items: center;
  justify-content: center;
  background-color: #010208;
  /* color: #fff;
  padding: 10px 20px; */
  /* border: none; */
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* .btn-modal img {
  height: 20px;
  margin-right: 10px;
} */
.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 10px;
  border-radius: 20px;
  max-width: 600px;
  min-width: 300px;
  background-color: #010208;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  color: white;
  background-color: #010208;
}


.bro {
  font-size: 50px;
  font-weight: bold;
}