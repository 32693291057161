@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap");

h3 {
  font-family: "Work Sans", sans-serif;
  font-weight: 800;
  margin-bottom: 20px;
  margin-left: 10vh;
  margin-right: 10vh;
}

body,
html {
  height: 100%;
  height: fit-content;
  cursor: default;
}
body {
  margin: 0;
}
/* Adding scroll snap type mandatory
and scroll direction to y axis*/
.container {
  overflow: hidden;
}
::-webkit-scrollbar {
    display: none;
  }
/* Adding general css to box and
aligning snap to start */
.box2 {
  height: 100vh;
  color: #fff;
  scroll-snap-align: start;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.box2 img {
  max-width: 100%; /* Limit the maximum width of the image to 100% */
  max-height: 100%; /* Limit the maximum height of the image to 100% */
  width: 100%; /* Allow the width to adjust automatically based on the image's aspect ratio */
  height: auto; /* Allow the height to adjust automatically based on the image's aspect ratio */
  object-fit: cover;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive iframe {
  margin-bottom: 100px;
  margin-top: 100px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
