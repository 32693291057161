.cursor {
    width: 4px;
    height: 4px;
    background-color: #ffffff;
    border-radius: 10px;
    position: absolute;
    z-index: 2000;
    transition-duration: 1.2s;
    transition-timing-function:  cubic-bezier(0.07, 1.18, 1, 1);
  }
  .cursor-follower {
    position: absolute;
    width: 15px;
    height: 15px;
    border: 2px solid #ffffff;
    border-radius: 100px;
    z-index: 2000;
    transition-duration: 0.1s;
    transition-timing-function:  cubic-bezier(0.07, 1.18, 1, 1);
  }
