

body, html{
    height:100%;
  }
  .swiper-container,
  .swiper-wrapper,
  .swiper-slide{
    width: 100%;
    height: 100% !important;
    /* height : window.innerHeight; */
  }
   .swiper-slide {
     text-align: center;
     font-size: 18px;
     background: #fff;
     /* Center slide text vertically */
     display: flex;
     justify-content: center;
     align-items: center;
     
     .box3:nth-child(1){
       background: #D8E2DC;
     }
     .box3:nth-child(2){
       background: #FFE5D9;
     }
     .box3:nth-child(3){
       background: #FFCAD4;
     }
     .box3:nth-child(4){
       background: #F4ACB7;
     }
  }

  body {
    margin: 0;
}
/* Adding scroll snap type mandatory
and scroll direction to y axis*/
.container1 {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    /* 100% height of the viewport */
    height: 100vh;
}
/* Adding general css to box and
aligning snap to start */
.box3 {
    height: 100vh;
    color: #fff;
    text-align: center;
    line-height: 100vh;
    font-size: 5rem;
    scroll-snap-align: start;
    overflow-y:hidden;
}
/* Setting different colors to all boxes */
.box3:nth-child(1) {
    background: #5e38ff;
    background-image: url("./pictures/1.png");
}
.box3:nth-child(2) {
    background: #fe802b;
    background-image: url("./pictures/2.png");
}
.box3:nth-child(3) {
    background: #00bf71;
    background-image: url("./pictures/3.png");
}
.box3:nth-child(4) {
    background: #5e38ff;
    background-image: url("./pictures/4.png");
}
.box3:nth-child(5) {
    background: #fe802b;
    background-image: url("./pictures/5.png");
}

/* modal section here */

.container {
  padding: 2rem 0rem;
}

@media (min-width: 576px){
  .modal-dialog {
    max-width: 400px;
    
    .modal-content {
      padding: 1rem;
    }
  }
}

.modal-header {
  .close {
    margin-top: -1.5rem;
  }
}

.form-title {
  margin: -2rem 0rem 2rem;
}

.btn-round {
  border-radius: 3rem;
}

.delimiter {
  padding: 1rem;  
}

.social-buttons {
  .btn {
    margin: 0 0.5rem 1rem;
  }
}

.signup-section {
  padding: 0.3rem 0rem;
}