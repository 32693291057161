
.container2 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background:#030203;
  z-index:1;
  align-items: center;
  justify-content: center;
}

.container4 {
  display:flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -52%;
  left: 0;
  z-index: 1;
 
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  transform: rotate(180deg);

}



