/* Navbar Styles */
body {
  overflow-y: auto;/* Adjust this value based on your header's height */
}
.navbar1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #010208;
    color: white;
    transition: background-color 0.3s ease;


  }
  
  .logo1 {
    font-size: 24px;
    font-weight: bold;
    margin-left: 15px;
    color: rgba(195, 72, 158, 1);
  }
  
  .nav-links1 {
    list-style: none;
    display: flex;
    gap: 20px;
    margin-right: 120px;
    
  }
  
  .nav-links1 li a {
    text-decoration: none;
    color: white;
    font-weight: 600;
    transition: color 0.3s;
  }
  
  .nav-links1 li a:hover {
    color: rgba(195, 72, 158, 1);
  }

  li {
    margin: 0 6px;
  }

  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #010208;
    width: 100%;
    opacity: 0.8;
    z-index: 100;
  }