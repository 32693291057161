.fullscreen-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
     /* Replace [20] with the desired static value */
  }
  