/* SelectedWorks.css */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap");

#work {
  background-color: #030203;
}

.selected-works-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* padding: 20px; */
  /* background-color: #010208; */
  gap: 30px;
}

.project-box {
  display: flex;
  align-items: center;
  width: 1200px;
  /*border: solid 5px #010208;
  */
  overflow: hidden;
  margin: 30px;
  padding-top: 100px;
  padding-bottom: 100px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  z-index: 20;
}

.even {
  flex-direction: row-reverse;
  height: 500px;
  box-shadow: 5px 5px 3px rgb(25, 25, 25), -5px -5px 7px rgb(29, 28, 28);
}

.odd {
  flex-direction: row;
  height: 500px;
  box-shadow: 5px 5px 3px rgb(34, 32, 32), -5px -5px 7px rgb(31, 30, 30);
}


.project-image {
  flex: 1;
  padding: 10px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
}
.project-image img {
  max-width: 100%;
  height: auto;
  border-radius: 25px;
}

.project-details {
  flex: 1;
  padding: 10px;
  margin: 20px;
  padding-bottom: 10px;
  text-align: left;
}

.project-details h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: white;
  text-align: left;
  padding-left: 10px;
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  line-height: 1.6;
}

.project-details p {
  font-size: 32px;
  margin-bottom: 20px;
  color: white;
  text-align: left;
  padding: 10px;
  padding-bottom: 20px;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  line-height: 1.6;
}

.project-details a {
  text-decoration: none;
  color: white;
  font-size: "Work-Sans";
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: white solid 2px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.project-details a:hover {
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .project-box {
    flex-direction: column;
    width: 400px;
    margin: 10px;
    height: 500px;

  }

  .project-image {
    order: 2;
    margin-bottom: 15px;
    width: 200px;
    height: 500px;
  }

  .project-details {
    order: 1;
    text-align: center;
  }
}

#Selected {
  padding-left: 100px;
  padding-top: 50px;
  font-family: "Work Sans", sans-serif;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  -webkit-transform: translateY(150px);
  -moz-transform: translateY(150px);
  -ms-transform: translateY(150px);
  -o-transform: translateY(150px);
  opacity: 0;
  transition: all 2s ease;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  -o-transition: all 2s ease;
}

.reveal.active {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  opacity: 1;
}
