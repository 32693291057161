.cursor1 {
    width: 4px;
    height: 4px;
    background-color: #030208;
    border-radius: 10px;
    position: absolute;
    z-index: 11;
    transition-duration: 1.2s;
    transition-timing-function:  cubic-bezier(0.07, 1.18, 1, 1);
    
  }
  .cursor1-follower {
    position: absolute;
    width: 15px;
    height: 15px;
    border: 2px solid #030208;
    border-radius: 100px;
    transition-duration: 0.1s;
    transition-timing-function:  cubic-bezier(0.07, 1.18, 1, 1);
  }
