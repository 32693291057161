body {
  margin: 0;
  /* height: fit-content; */
}

.container {
  overflow: hidden;
}
::-webkit-scrollbar {
    display: none;
  }
  
/* Adding general css to box and
aligning snap to start */
.box {
  /* height: 100vh; */
  color: #fff;
  scroll-snap-align: start;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Setting different colors to all boxes */
.box img {
  max-width: 100%; /* Limit the maximum width of the image to 100% */
  max-height: 100%; /* Limit the maximum height of the image to 100% */
  width: 100%; /* Allow the width to adjust automatically based on the image's aspect ratio */
  height: auto; /* Allow the height to adjust automatically based on the image's aspect ratio */
  object-fit: cover;
}

body,
html {
  height: 100%;
}
