@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@250&display=swap');

.containerz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0%;
  /* font-size: 50px; */
  color: white;
  max-width: 1100px;
  width: auto;
  height: auto;
  opacity: 0.9;
  z-index:1;
}

.containerz1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 20px; /* Adjust px-20 to your preferred value */
  margin-top: 40px; /* Adjust mt-40 to your preferred value */
  width: 100%;
  z-index: 1;
}

.bro {
  font-size: 50px;
  font-weight: bold;
}

 .containerz p {
  margin: 0;
  font-size: 53px;
  /* opacity: 0.9; */
  text-align: center;
  /* font-family: sans-serif; */
  font-family: "Work Sans", sans-serif;
  line-height: 1.5;
  font-weight: 200;
}
