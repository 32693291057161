/* Navbar Styles */
.navbar {
  display: flex;
  height: 70px;
  position: fixed;
  z-index: 1000;
  padding-left: 10px;
  padding-right: 10px;
  width: full;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: white;
  transition: background-color 0.3s ease;
  /* filter: blur(10px);  */

}

.logo {
  z-index: 2000;
  font-size: 24px;
  font-weight: bold;
  margin-left: 15px;
  color: rgba(195, 72, 158, 1);
}

.nav-links {

  list-style: none;
  display: flex;
  gap: 20px;
  margin-right: 120px;
}

.nav-links li a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  position: relative; 
  /* Added to position the pseudo-element */
  
}

.nav-links li a::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: rgba(195, 72, 158, 1); /* Underline color */
  bottom: -3px;
  left: 0;
  transition: width 0.3s ease; /* Transition for the underline */
}

.nav-links li a:hover::before {
  width: 100%; /* Increase width on hover */
  
}
.nav-links li a:hover {
  color: rgba(195, 72, 158, 1);
}
.sticky {
  position: fixed;
  top: 0;
  background-color: #010208;
  width: 100%;
  opacity: 0.8;
}
